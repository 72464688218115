.nft.active {
  transition: all 2s ease-in-out;
  opacity: 100;
}
.nft {
  opacity: 0;
}
.profile-dropdown-away {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
/* line clump */
.line-clump-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line-clump-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.line-clump-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.line-clump-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.line-clump-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
/* line clump end */
/* slick slider */
.slick-slide {
  margin-right: 0;
  margin-left: 0;
}
/* slick slider end */
/* home one */
.artist-carousel-active {
  position: relative;
}
.single_business_process {
  width: 100%;
  height: 100%;
}
.single-testimonial {
  margin: 0 15px;
}
.day-filter.my-select-box .my-select-box-btn {
  background: none;
  border: none;
  font-size: 36px;
  color: #1351fc;
  font-family: Poppins;
  font-weight: 600;
  box-shadow: none;
  padding: 0;
}
.day-filter.my-select-box .my-select-box-section li {
  font-size: 16px;
  color: #1351fc;
}
.explore-section-select-box.my-select-box .my-select-box-btn {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 0px;
  border: solid 1px #e8e8e8;
  font-size: 18px;
  height: 56px;
  line-height: 56px;
  width: 200px;
  font-weight: 500;
  font-family: Urbanist;
}
.explore-section-select-box.my-select-box .my-select-box-btn span {
  color: black !important;
}
.explore-section-select-box.my-select-box .my-select-box-section li {
  font-size: 18px;
}
.language-select-box.my-select-box {
  position: relative;
}
.language-select-box.my-select-box .my-select-box-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.13) 7.27%,
    rgba(255, 255, 255, 0.21) 97.33%
  );
  color: #fff;
  border: none;
  width: 300px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 0px;
}
.language-select-box.my-select-box .my-select-box-section {
  width: 300px;
}
#scrollUp {
  position: fixed;
  z-index: 999999999;
  cursor: pointer;
}
/* home one end */
/* home two */
.day-filter-two.day-filter.my-select-box .my-select-box-btn {
  color: #f94025;
}
.day-filter-two.day-filter.my-select-box .my-select-box-section li {
  color: #f94025;
}
.quomodo-r-live-auction.live-auction-h3.home3-cowndown .artist-btn {
  margin-top: 50px;
}
.footer-area.footer2-area.footer3-area .my-select-box-btn {
  background: #fff;
  border-radius: 6px;
  color: #04091e;
}
.top-collectoin-area.top-collectoin-area-h3 .top-collection-title h2 {
  color: #04091e;
}

.live-auction-area.artist-carousel-area.live-auction-h3 .section-title h2 {
  color: #04091e;
}
/* home two end */
/* home dark  */
.top-collectoin-area .top-collection-title h2 {
  color: #ffffff;
}
.top-collectoin-area
  .day-filter-two.day-filter.my-select-box
  .my-select-box-btn {
  color: #34caa5;
}
.top-collectoin-area
  .day-filter-two.day-filter.my-select-box
  .my-select-box-section
  li {
  color: #34caa5;
}
.live-auction-area .section-title h2 {
  color: #ffffff;
}
.footer-area.footer2-area .my-select-box-btn {
  background: #111529;
  border-radius: 6px;
}
.live-auction-area .auctions-btn {
  margin-top: 60px;
}

/* home dark end */
.artist-carousel-active strong.priv_arrow {
  position: absolute;
  top: -115px;
  right: 90px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: #131720 !important;
  font-size: 18px !important;
  transition: 0.3s;
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cdd6d7;
  transition: 0.3s;
}
.artist-carousel-active strong.next_arrow {
  position: absolute;
  top: -115px;
  right: 15px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: #131720 !important;
  font-size: 18px !important;
  transition: 0.3s;
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cdd6d7;
  transition: 0.3s;
}
.modal {
  background: none;
}
.single-profile-away.active {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.modal-single-wrap {
  z-index: 9999;
}
.header-area {
  z-index: 99999;
}
.mobile-deshboard .tab-content > .active {
  width: 100%;
}

@media (max-width: 425px) {
  .explore-section-select-box.my-select-box .my-select-box-btn {
    width: 100%;
    margin-bottom: 10px;
  }
  /* .single_business_process {
    margin-top: 0;
    margin-bottom: 85px !important;
  }
  .process-area
    .col-xl-3.col-lg-3.col-md-6:last-child
    .single_business_process {
    margin-top: 15px;
  } */
}
